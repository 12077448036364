:root {--main-theme-color:#1654C3;--main-primary-color:#000000;}
* {box-sizing: border-box;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;outline: 0 !important;}
html, body {
    width: 100%;
    min-height: 100%;
}
body {font-family: 'Mulish', sans-serif;overflow-x: hidden;margin: 0;background: url(../../img/foloosi_banner.png) no-repeat bottom;min-height: 100vh;}
img {max-width: 100%;}
a {cursor: pointer;}
a:active,a:focus,a:hover {outline: 0;text-decoration: none;}
.btn:focus{outline: 0;box-shadow: none;}
.form-control:focus{box-shadow: none;}
.b-none {
    border: 0!important;
}

.b-b-none {
    border-bottom: 0 !important;
}

.p-0 {
    padding: 0!important;
}

.p-10 {
    padding: 10px!important;
}

.p-20 {
    padding: 20px!important;
}

.p-30 {
    padding: 30px!important;
}

.p-l-0 {
    padding-left: 0!important;
}

.p-l-5
{
    padding-left: 5px!important;
}

.p-l-10 {
    padding-left: 10px!important;
}

.p-l-15 {
    padding-left: 15px!important;
}

.p-l-20 {
    padding-left: 20px!important;
}

.p-l-25 {
    padding-left: 25px!important;
}

.p-r-0 {
    padding-right: 0!important;
}

.p-r-5
{
    padding-right: 5px!important;
}

.p-r-10 {
    padding-right: 10px!important;
}

.p-r-15 {
    padding-right: 15px!important;
}

.p-r-25 {
    padding-right: 25px!important;
}

.p-r-40 {
    padding-right: 40px!important;
}

.p-t-0 {
    padding-top: 0!important;
}

.p-t-5 {
    padding-top: 5px!important;
}

.p-t-10 {
    padding-top: 10px!important;
}

.p-t-15 {
    padding-top: 15px!important;
}

.p-t-20 {
    padding-top: 20px!important;
}

.p-t-30 {
    padding-top: 30px!important;
}

.p-b-0 {
    padding-bottom: 0!important;
}

.p-b-5 {
    padding-bottom: 5px!important;
}

.p-b-10 {
    padding-bottom: 10px!important;
}

.p-b-15 {
    padding-bottom: 15px!important;
}


.p-b-20 {
    padding-bottom: 20px!important;
}

.p-b-30 {
    padding-bottom: 30px!important;
}

.m-0 {
    margin: 0!important;
}

.m-l-5 {
    margin-left: 5px!important;
}

.m-l-10 {
    margin-left: 10px!important;
}

.m-l-15 {
    margin-left: 15px!important;
}

.m-l-20 {
    margin-left: 20px!important;
}

.m-l-30 {
    margin-left: 30px!important;
}

.m-l-40 {
    margin-left: 40px!important;
}

.m-l-50 {
    margin-left: 50px!important;
}

.m-r-5 {
    margin-right: 5px!important;
}

.m-r-10 {
    margin-right: 10px!important;
}

.m-r-15 {
    margin-right: 15px!important;
}

.m-r-20 {
    margin-right: 20px!important;
}

.m-r-30 {
    margin-right: 30px!important;
}

.m-r-40 {
    margin-right: 40px!important;
}

.m-r-50 {
    margin-right: 50px!important;
}

.m-t-0 {
    margin-top: 0!important;
}

.m-t-5 {
    margin-top: 5px!important;
}

.m-t-10 {
    margin-top: 10px!important;
}

.m-t-15 {
    margin-top: 15px!important;
}

.m-t-20 {
    margin-top: 20px!important;
}

.m-t-25 {
    margin-top: 25px!important;
}

.m-t-30 {
    margin-top: 30px!important;
}

.m-t-40 {
    margin-top: 40px!important;
}
.m-t-50 {
    margin-top: 50px!important;
}

.m-b-0 {
    margin-bottom: 0!important;
}

.m-b-5 {
    margin-bottom: 5px!important;
}

.m-b-10 {
    margin-bottom: 10px!important;
}

.m-b-15 {
    margin-bottom: 15px!important;
}

.m-b-20 {
    margin-bottom: 20px!important;
}

.m-b-25 {
    margin-bottom: 25px!important;
}

.m-b-30 {
    margin-bottom: 30px!important;
}

.m-b-40 {
    margin-bottom: 40px!important;
}

.m-b-50 {
    margin-bottom: 50px!important;
}

.font-regular {
    font-weight: 400;
}

.font-medium {
    font-weight: 500;
}

.font-semibold {
    font-weight: 600;
}

.font-bold {
    font-weight: 700;
}

.fpl_body{height: 100%;padding:0px;margin: 0 auto;max-width: 900px;flex-direction: column;display: flex;justify-content: center;align-items: center;flex-wrap: wrap;width: 100%;min-height:100vh;transition: all 2s ease 0s;-webkit-transition: all 2s ease 0s;-moz-transition: all 2s ease 0s;-ms-transition: all 2s ease 0s;}
.fpl_body_center{width:100%;display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;padding: 20px 0;}
.fpl_merchant_left{background: #FFFFFF;box-shadow: 0px -2px 11px rgba(0, 0, 0, 0.06);border-radius:0px;}
.fpl_merchant_leftTop{display: flex; width: 100%; background: var(--main-theme-color);padding: 15px 12px;border-radius:0;align-items: center;}
.fpl_smalLogo_wrap{padding: 3px 6px;background: #fff;border-radius: 11px;float:left;}
.fpl_smalLogo{display: inline-block;height: 37px;width: 32px;object-fit: contain;-webkit-object-fit:contain;}
.fpl_merchant_name{float: left;width: 100%;font-size: 17px;font-weight: 800;color: #FFFFFF;padding-left: 10px;}
.fpl_merchant_leftMid{float: left;width: 100%;background: #fff;padding: 20px 12px;}
.fpl_merchant_midTitle{float: left;width: 100%;color: #686868;font-size: 12px;font-weight: 400;padding-bottom: 10px;}
.fpl_merchant_midDesc{float: left;width: 100%;color: #303030;font-size: 14px;font-weight: 400;padding-bottom: 20px;}
.fpl_merchant_midAed{float: left;width: 100%;color: var(--main-theme-color);font-size: 20px;font-weight: 600;}
.fpl_merchant_leftBot{display: flex; width: 100%; background: #F5F5F5;padding: 15px 25px;border-radius: 0 0 16px 16px;align-items: center;}
.fpl_merchant_right{background: #FFFFFF;border: 2px solid #FAFAFA;box-sizing: border-box;box-shadow: 0px -1.715px 9.4325px rgba(0, 0, 0, 0.06);backdrop-filter: blur(10px);border-radius: 12px;}
.fpl_merchant_rightTop{display: flex;align-items: center;background: #FEFEFE;padding: 15px 10px;}
.fpl_merch_detail{float: left;font-size: 11px;font-weight: 800;text-transform: uppercase;color: var(--main-theme-color);}
.fpl_merchant_rightMid{float: left;width: 100%; background: #fff;padding: 15px 10px 5px;box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);-webkit-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);-ms-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);}
.fpl_detail{float: left;width: 100%;position: relative;}
.fpl_detail input{float:left;width: 100%;background: #F9F9F9;border-radius: 6px;padding: 10px;margin-bottom: 20px;border: none;font-size: 12px;font-weight: 400;color: #333;height: 38px;line-height: 18px;}
.fpl_detail_btn{float: left;width: 100%;padding: 0 15px;}
.fpl_detail_btn button{float: left;width: 100%;text-align: center;padding: 10px;background: var(--main-theme-color);border-radius: 5px;border: 1px solid var(--main-theme-color);color: #FFFFFF;margin-bottom: 25px;margin-top: 10px;font-size: 14px;font-weight: 600;}
.flp_pay_detail{float: left;width: 100%;background: #FAFAFA;box-shadow: 0px -9.4325px 12.8625px rgba(0, 0, 0, 0.05);border-radius: 13px;padding: 20px 10px;}
.flp_pay_detailWrap{position: relative;}
.flp_pay_detailTxt{float: left;width: 100%;color: #144378;font-size: 11px;font-weight: 800;margin-bottom: 5px;}
.flp_pay_detailCard{float: left;width: 100%;color: #757575;font-size: 12px;font-weight: 400;}
.flp_pay_arrow{position: absolute;top: 5px;right: 18px;}
.fpl_merchant_rightBot{background: #F9F9F9;box-shadow: 0px -9.4325px 12.8625px rgba(0, 0, 0, 0.05);border-radius: 0 0 12px 12px;padding: 15px;float: right;width: 100%;}
.fpl_merchant_rightBot img{margin-left: 10px;float: right;height: 12px;}
.flp_mainBot{width: 100%;text-align: center;display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;justify-content: center;-webkit-justify-content: center;-ms-justify-content: center;padding:10px 0;}
.flp_powered{float: left;width: 100%;color: #686868;font-size: 12px;font-weight: 400;margin-bottom:0px;}
.flp_main_botDesc{float: left;width: 100%;color: #686868;font-size: 12px;font-weight: 400;margin-top: 20px;}
.clr_blue{color: var(--main-theme-color);}
.relative{position: relative;}
.fpl_merch_detailName{float: left;width: 100%;font-size: 14px;font-weight: 700;color: #303030;margin-bottom: 5px;}
.fpl_merch_topArrow{position: absolute;top: 7px;right: 18px;}
.fpl_mob_wrap{float: left;width: 100%;margin-bottom:0px;}
.fpl_merch_detailMob{display: inline-block;font-size: 12px;font-weight: 400;}
.fpl_mob_wrap span{padding: 0 5px;}
.fpl_merch_detailEmail{display: inline-block;font-size: 14px;font-weight: 400;}
.fpl_detail label{float: left;width: 100%;font-size: 12px;font-weight: 500;color: #606060;margin-bottom: 10px;}
.fpl_detail_relative{position: relative;}
.fpl_detail_relative img{position: absolute;top: 37px;right: 18px;}
.fpl_detail_left{float: left;width:50%;padding:0 5px 0 0;position: relative;}
.fpl_detail_mid{float: left;width:25%;padding:0 2.5px}
.fpl_detail_right{float: right;width:25%;padding:0 0 0 5px;}
.pci_icon{display:none;}
.fpl_detail_section{float: left;width: 100%;padding: 15px 10px;}
.status_heading{font-size:18px;margin: 0 0 12px;float: left;width: 100%;font-weight:700;}
.status_trans_id{color:#303030;font-size: 13px;margin:0 0 10px;float: left;width: 100%;}
.status_trans_icon{margin:10px 0 20px;float: left;width: 100%;text-align: center;}
.status_trans_icon img{height:75px;}
.status_trans_message{font-size: 13px;margin:0 0 15px;float: left;width: 100%;}
.status_bold{font-size: 24px;margin:15px 15px;width: 100%;text-align:center;}
.status_done_btn{font-size:13px;display:inline-block;padding:5px 10px;color:#0EA18D;border:1px solid #0EA18D;border-radius:5px;margin:0 0 20px;}
.status_error_btn{font-size:13px;display:inline-block;padding:5px 10px;color:#FF5555;border:1px solid #FF5555;border-radius:5px;margin:0 0 20px;}
.status_failure{color:#FF5555;}
.status_success{color:#0EA18D;}
.text-center{text-align: center;}
input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active{background: none !important;}
.width_100{float:left;width:100%;}
.pay_popup_submit{float: left;width: 100%;text-align: center;padding: 10px;background: var(--main-theme-color);border-radius: 5px;border: 1px solid var(--main-theme-color);color: #FFFFFF;margin-bottom: 25px;margin-top: 10px;font-size: 14px;font-weight: 600;}
.pay_popup_submit_disable{float: left;width: 100%;text-align: center;padding: 10px;background: #195dd4;border-radius: 5px;border: 1px solid #195dd4;color: #FFFFFF;margin-bottom: 25px;margin-top: 10px;font-size: 14px;font-weight: 600;}
.pay_popup_submit_disable:hover {text-decoration: none;color: #fff;}
.loaderIcon{padding:30px 0;text-align:center;float: left;width: 100%;}
.loading_text_contFirst{font-size:25px;color:var(--main-primary-color);margin:30px 0 0;font-weight:700;}
.loading_text_cont{font-size:13px;color:#555;margin:20px 0 0;}
.loading_image_wrapper{position: fixed;left: 0;top: 0;width: 100%;height: 100%;z-index: 10000000001;text-align: center;opacity: 1;visibility: visible;-webkit-transition: opacity 0.4s,visibility -0.3s linear 0.5s;transition: opacity 0.4s,visibility -0.3s linear 0.5s;}
.loading_image_inner{position: absolute;top: 50%;left: 50%;-webkit-transform: translate(-50%,-50%   );-ms-transform: translate(-50%,-50%);transform: translate(-50%,-50%);}
.merDates{float: right;font-size: 14px; text-align: right;color: #303030;}
.error_message_bottom {position: absolute;bottom: 6px;font-size: 11px;color: #FF5555;left:0px;z-index:10;line-height: 11px;}
.foloosiPluginApiiframeWrapper{float: left;width:100%;-webkit-overflow-scrolling: touch;margin:0px;position: relative;}
.frame-open{display:block;}
.frame-close{display:none;}
.leftWrapContPhone{float: left;width:100px;position: relative;border: 0;background: #F9F9F9;height:38px;}
.leftWrapContPhone:after{background-color: #cfcfcf;height: 15px;width: 2px;content: "";position: absolute;top: 16px;right: 0;}
.leftWrapContPhone .country-list {position: absolute;z-index: 2;list-style: none;text-align: left;padding: 0;margin: 0 0 0 -1px;box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);background-color: white;border: 1px solid #CCC;max-height:170px;overflow-y: scroll;bottom:100%;min-width:280px;}
.leftWrapContPhone .country-list.dropup {bottom: 100%;margin-bottom: -1px;}
.leftWrapContPhone .country-list .flag-box {display: inline-block;width: 20px}
.leftWrapContPhone .country-list .divider {padding-bottom: 5px;margin-bottom: 5px;border-bottom: 1px solid #CCC}
.leftWrapContPhone .country-list .country {padding: 5px 10px}
.leftWrapContPhone .country-list .country .dial-code {color: #999}
.leftWrapContPhone .country-list .country.highlight {background-color: rgba(0, 0, 0, 0.05)}
.leftWrapContPhone .country-list .flag-box,.leftWrapContPhone .country-list .country-name,.leftWrapContPhone .country-list .dial-code {vertical-align: middle}
.leftWrapContPhone .country-list .flag-box,.leftWrapContPhone .country-list .country-name {margin-right: 6px}
.country-list li{cursor: pointer;}
.country-list li:hover {background-color:#ececec;}
.rightWrapContPhone{float: right;width: calc(100% - 100px);position: relative;}
.country_flag{float: left;width:46px;height: 100%;}
.country_flag .selected-flag {z-index: 1;position: relative;width: 46px;height: 100%;padding: 0 0 0 8px;}
.selected-flag .iti-flag {position: absolute;top: 0;bottom: 0;margin: auto;}
.country_flag .selected-flag .iti-arrow{position:absolute;top:50%;margin-top:-2px;right:6px;width:0;height:0;border-left:3px solid transparent;border-right:3px solid transparent;border-top:4px solid #555}
.country_flag .selected-flag .iti-arrow.up{border-top:none;border-bottom:4px solid #555}
.phone_code {line-height:38px;padding: 0 2px;float:left;width:52px;text-align: center;}
.hide{display: none;}
.custom_check_box{position: relative;font-weight: 500;font-size: 12px;padding-left: 25px;margin: 0 0 15px !important;width: auto !important;float: none !important;display: inline !important;}
.custom_check_box::before{position: absolute;top: 0;left: 0;height: 16px;width: 16px;background-color: #eee;content: "";border-radius: 3px;}
.custom_check_box::after{position: absolute;left: 6px;top: 3px;width: 4px;height: 8px;border: solid #fff;border-width: 0 2px 2px 0;transform: rotate(45deg);content: "";}
.otp_check_cust:checked + .width_100 .custom_check_box::before{background-color: var(--main-theme-color);}
.otp_check_cust:checked + .width_100 .custom_check_box::after{border: solid #fff;border-width: 0 2px 2px 0;transform: rotate(45deg);}
.checkmark_cust:checked + .width_100 .custom_check_box::before{background-color: var(--main-theme-color);}
.checkmark_cust:checked + .width_100 .custom_check_box::after{border: solid #fff;border-width: 0 2px 2px 0;transform: rotate(45deg);}
.pay_popup_card_country svg{width:15px!important;color:rgb(204, 204, 204);}
#react-select-2-input,#react-select-3-input{height: 18px;line-height: 18px;margin: 0;}
[class$="Input"] div{vertical-align:top;}
.pay_popup_card_country{float:left;width: 100%;background: #F9F9F9;border-radius: 6px;padding: 10px;margin-bottom: 20px;border: none;font-size: 12px;font-weight: 400;color: #333;height: 38px;line-height: 18px;}
.pay_popup_card_country_error{border-bottom: 1px solid red;}
.error_message_top{float: left;width: 100%;color: #ff0000;font-size: 12px;margin:0 0 10px;text-align: center;}
.paymode_section{float: left;;width: 100%;text-align: center;padding: 50px 0;}
.paymode_section_split{float: left;width: 100%;font-size: 16px;font-weight: 500;color: #d2cfcf;padding: 20px 0;position: relative;}
.paymode_section_split::before{position: absolute;top: calc(50% + 1px);left: calc(50% - 80px);right: calc(50% + 22px);background: #d2cfcf;content: "";height: 1px;}
.paymode_section_split::after{position: absolute;top: calc(50% + 1px);right: calc(50% - 80px);left: calc(50% + 22px);background: #d2cfcf;content: "";height: 1px;}
.paymode_section_btn_warp a{display: inline-block;cursor: pointer;}
.paymode_section_btn_warp{display: inline-block;width: 100%;}
.paymode_section_credit{padding:8px 15px;font-size: 15px;font-weight: 600;color: #fff;background: #195dd4;min-width: 212px;min-height: 38px;border-radius: 3px;}
.paymode_section_credit:hover{color: #fff;background: #195dd4;text-decoration: none;}
.infoWarningTextTwo{float: left;width: 100%;font-size:12px;color:#ff0000;margin:15px 0 0;text-align: left;}
.infoWarningTextTwo i{margin-right: 5px;}
.redtitle{color:#f54443; font-size:20px;font-weight: 600;margin-top: 16px;text-align: center;line-height: 20px;}
.detaildesc{font-size: 15px;color:#777777;margin-top: 16px;text-align: center;line-height: 20px; text-align: center;font-weight: 400;}
.pay_pop_card_img {position: absolute;right:20px;top:0px;width:36px;height:18px;float: left;background-size: 100% 100%;}
#foloosiPluginApiiframe{border: 0;width:100%;height:100%;overflow-y: auto;}
#foloosiPluginApiiframe.frame-open{display:block;}
#foloosiPluginApiiframe.frame-close{display:none;}
.bg_remove{background:none!important;}
#foloosiPluginApiiframe{min-height:320px!important;}
.fpl_detail.fpl_detail_50{width:50%;}
.fpl_detail.fpl_detail_75{width:75%;}
.fpl_detail.fpl_detail_25{width:25%;}
.space_right{padding-right: 5px;}
.space_left{padding-left: 2.5px;}
.fl_pay_section{float: left;width: 100%;padding: 50px 0;}
.country-list li{float: left;width: 100%;}
.search_input{position: sticky;position:-webkit-sticky;top:0;border-bottom:0px solid #e1e1e1;float: left;width: 100%;}
.search_input input{margin:0;box-shadow: 0 0 5px 1px lightgrey;-webkit-box-shadow: 0 0 5px 1px lightgrey;background:#fff;padding:8px 10px;font-size:12px;height:auto;}
.search_input input:focus{border:0;outline:0;}
.flp_powered img{height:25px;margin-left:10px;}
.fpl_merchant_right_middle{float:left;width:100%;background: #FEFEFE;padding: 5px 0px 15px;}
.fpl_merchant_leftTop_right{float:left;width:calc(100% - 70px);margin-left:20px;}
.fpl_merchant_leftTop_right_full{float:left;width:100%;margin-left:0px;}
.fpl_payment_id{float: left;width: 100%;font-size: 14px;font-weight: 500;color: #FFFFFF;margin-bottom:0px;}
.link_paid_txt{font-size:18px;color:var(--main-primary-color);margin:30px 0 30px;font-weight:700;}
.cancel_img{height:130px;}
@media (min-width:360px) and (max-width:767px){
  
.fpl_body_center{width:100%;display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;padding: 20px 0; overflow-y: scroll;}
    .fpl_merchant_left{width:100%;}
    .fpl_merchant_right{width:100%;margin:0;}
    .fpl_merchant_leftBot{display: none;}
    .flp_pay_detail{display: none;}
    .pci_icon{display:block;float:left!important;}
    .responsive_view{
      max-height: 350px !important;
      max-width: 425px !important;
    }
    .thumbnails {
      max-width: 425px!important;
    }
    
}
.product_wrapper{
    float: left;
}
.productleft_wrapper{
    float: left;
}
.product_name {
    float: left;
    width: 100%;
    font-size: 22px;
    padding: 13px 0 10px 15px;
    font-weight: 700;
  }
  .product_image{
    float: left;
    width: 100%;
    margin: 20px 0 20px;
    text-align: center;
  }
  .product_image a img{
    height: 100px;
    width: 100px;
    object-fit: contain;
  }
  .product_wrap {
    float: left;
    width: 100%;
    margin: 10px 0 25px;
  }
  .product_content, .product_content_text h2 {
    float: left;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 12px;
  }
  .product_content_text, .product_content_text p {
    float: left;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
  }
  .share_images {
    float: left;
    width: 100%;
  }
  .shareon_img {
    float: left;
  }
  .email_icon {
    float: left;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
  }
  .mail_content {
    padding-left: 12px;
  }
  /* .field-wrapper {
    height: 36px;
    min-width: 160px;
    max-width: 360px;
    color: #0d2366;
  }
  .field-addon--before {
    left: 10px;
    font-size: 14px;
    font-weight: bold;
  }
  .field--currency-1 .field-addon--before + .field-el {
    padding-left: 20px;
  }
  .field-addon--after {
    right: 9px;
  }
  .field-addon > * {
    display: table-cell;
    vertical-align: middle;
  }
  .field-addon > * > .field-content {
    margin: 0;
  }
  .Field--counter .Field-wrapper {
    min-width: 96px;
    max-width: 96px;
    height: 24px;
  }
  .field--counter .field-wrapper .counter-value {
    width: 100%;
    padding: 0 32px;
    display: block;
    text-align: center;
    background: #fff;
  }

  .UI-form.UI-form .Field--currency-1 .Field-addon--before + .Field-el {
    padding-left: 20px;
  }
  .UI-form.UI-form .Field--amount div.Field-el, .UI-form.UI-form .Field--amount input.Field-el {
    background: #fcfcfc;
    border: 1px solid #e3e9eb;
  }
  .UI-form.UI-form .Field--amount div.Field-el {
    pointer-events: none;
  }
  .UI-form.UI-form .Field-el {
    line-height: 16px;
    height: 100%;
    width: 100%;
    background: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 2px;
    overflow: hidden;
    font-size: 14px;
    padding: 0 9px;
      padding-left: 9px;
    outline: none;
    color: inherit;
  } */

  .counter-value{
    width: 30px;
    background-color: #fff;
    border: none;
    }
    .filed-ls{
        float: left;
        color: #0d2366;
        
    }
    /* .Field-addon{float: ;}  */
.plusbut{
    background-color: #fff;
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;
    border: none;
    border-left: 1px solid #e2e2e2;
    color: rgb(35,113,236);
    width: 28px;
    height: 22px;
    font-size: 20px;
    background: transparent;
    padding: 0 0 3px 1px;
    cursor: pointer;
    top: 0;
    line-height: 100%;
    font-weight: bold;
}
.dddd{border: 1px solid red;}
.minsbut{
    background-color: #fff;
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;
    border: none;
    border-right: 1px solid #e2e2e2;
    color: rgb(35,113,236);
    width: 28px;
    height: 22px;
    font-size: 20px;
    background: transparent;
    padding: 0 0 3px 1px;
    cursor: pointer;
    top: 0;
    line-height: 100%;
    font-weight: bold;
}
.filed_input{
    float: right;
    width: 60%;
}
.filed_back{
    background: #fcfdff;
    border: 1px solid #dae7fc;
    height: 36px;
    min-width: 160px;
    max-width: 360px;
}
.field-wrapper {
    position: relative;
    padding: 5px 5px;
  }
  .field.field--counter {
    position: absolute;
    top: 0;
    right: 5px;
  }
  .counter-borter {
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;
    border: 1px solid #dae7fc;
  }
  .fpl_lab{
    margin-top: 10px;
    float: left;
    width: 100%;
    color: #606060;
  }
  .view{display: block; font-size: 11px;font-weight: 800;text-transform: uppercase;text-align: right;}
  .field-descr {
    float: right;
    width: 50%;
  }
  .field-eldes {
    float: right;
    padding-top: 8px;
  }
  .filed_fpl{
    float: left;
    width: 40%;
  }
  .main_images{width:300px;height:300px;}
  .thumb_image{width: 100px !important;height: 100px !important;object-fit:contain !important;margin:0 10px 10px 0 !important;background: #e9e9e9 !important;padding: 2px !important;}
  .field-addon{justify-content: space-between;width: 70px;}
  .aed_align {display: flex;text-align: center;}
  .aed_aligns {width: 65%;text-align: left; padding: 0 5px 0 0;}
  /* Chrome, Safari, Edge, Opera */
  .field-el::-webkit-outer-spin-button,
  .field-el::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.field-el{text-align: center;}

/* Firefox */
.field-el[type=number] {
  -moz-appearance: textfield;
  size: 4px;
}
.customer--notes {
    display: block;
  }
.customer--notes{max-height:150px;overflow-y: auto;padding-right:20px;font-size:13px;line-height:18px;margin-top:10px;text-align: justify;}

.customer--notes::-webkit-scrollbar {
    width: 10px;
}
.customer--notes::-webkit-scrollbar-track {
background: #555; 
}
.customer--notes::-webkit-scrollbar-thumb {
background: #888; 
}
.customer--notes::-webkit-scrollbar-thumb:hover {
background: #555; 
}

.carousel-image {
    vertical-align: middle;
    width: 100%;
    height: 350px;
  }
  .carousel-container {
    position: relative;
    margin: auto;
  }
  
  .carousel-item {
    width: 100%;
    height: 100%;
  }
  
  .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: #000;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
  }
  
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
  .prev {
    left: 0;
    border-radius: 3px 0 0 3px;
  }
  .prev:hover, .next:hover {
    background-color: rgba(0,0,0,0.8);
  }
  
  /* Caption text */
  .carousel-caption-bottom {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 0;
    width: 100%;
    position: absolute;
    bottom: 30px;
    width: 100%;
    text-align: center;
    text-shadow: 0px 0px 10px rgba(0,0,0,0.8);
  }
  .carousel-caption-center {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 0;
    width: 100%;
    position: absolute;
    bottom: 50%;
    top: 45%;
    width: 100%;
    text-align: center;
    text-shadow: 0px 0px 10px rgba(0,0,0,0.8);
  }
  
  .carousel-caption-top {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 0;
    width: 100%;
    position: absolute;
    top: 8px;
    width: 100%;
    text-align: center;
    text-shadow: 0px 0px 10px rgba(0,0,0,0.8);
  }
  .dots {
    margin-top: 10px;
    text-align: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
  }
  .slide-number {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
  }
  
  .dot {
    cursor: pointer;
    height: 8px;
    width: 8px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
  
  .active, .dot:hover {
    background-color: #717171;
  }
  
  .fadeImage {
    -webkit-animation-name: fadeImage;
    -webkit-animation-duration: 1.5s;
    animation-name: fadeImage;
    animation-duration: 1.5s;
  }
  .pause-icon {
    position: absolute;
    /* top: 38%; */
    left: 15px;
    bottom: 2px;
    /* right: 50%; */
    opacity: 1;
  }
  
  .pause {
    -webkit-animation-name: pause;
    -webkit-animation-duration: 0.5s;
    animation-name: pause;
    animation-duration: 0.5s;
  }
  
  .bar {
    width: 100%;
    background-color: #ddd;
    position: absolute;
      bottom: 0px;
      border-radius: 0 0 10px;
  }
  
  .progress {
    width: 1%;
    height: 5px;
    background-color: #042baa;
  }
  
  .thumbnails {
    display: flex;
    margin-top: 10px;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
  }
  .thumbnails::-webkit-scrollbar {
    display: none;
  }
  
  .thumbnail {
    margin: 0 5px;
  }
  .active-thumbnail {
    border: #476ff1 solid 3px;
  }
  @keyframes pause {
    0% {opacity: .2; }
    90% {opacity: 1; }
    
  }
  @-webkit-keyframes pause {
    0% {opacity: .2; }
    90% {opacity: 1;}
    
  }
  @-webkit-keyframes fadeImage {
    from {opacity: .4} 
    to {opacity: 1}
  }
  
  @keyframes fadeImage {
    from {opacity: .4} 
    to {opacity: 1}
  }
  
  @media only screen and (max-width: 500px) {
    .prev,
    .next,
    .carousel-caption-bottom, 
    .carousel-caption-center, 
    .carousel-caption-top, 
    .slide-number 
    {
      font-size: 20px !important;
    }
    .dot {
      height: 4px;
      width: 4px;
    }
    .carousel-container{
      max-height: 250px !important;
    }
    .thumbnail {
      max-width: 70px;
    }
    .carousel-caption-bottom{
      bottom: 25px;
    }
  }
  .clickToPayFrame{float: left;width:100%;-webkit-overflow-scrolling: touch;margin:0px;overflow-y: auto;position: absolute;top: 0;left: 0;bottom: 0;border: 0;height:100%;}
.otp_input {float:left;position: relative;padding-left: 35px;margin:0 20px 0 0;cursor: pointer;font-size:15px;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;font-weight: normal;text-transform: capitalize;}
.otp_input input {position: absolute;opacity: 0;cursor: pointer;}
.otp_check {position: absolute;top: 0;left: 0;height: 25px;width: 25px;background-color: #eee;border-radius: 50%;}
.otp_input:hover input ~ .otp_check {background-color: #ccc;}
.otp_input input:checked ~ .otp_check {background-color: #2196F3;}
.otp_check:after {content: "";position: absolute;display: none;}
.otp_input input:checked ~ .otp_check:after {display: block;}
.otp_input .otp_check:after {top: 9px;left: 9px;width: 8px;height: 8px;border-radius: 50%;background: #fff;}
.click2payOtpListShow{margin:0px;float:left;width:100%;padding: 12px;}
.otp_label{float:left;width:100%;font-size:13px;color:#000;margin:15px 0 20px;}
.paynow_card{font-size: 13px;font-weight: 400;color: #fff;background: var(--main-theme-color);padding:6px 10px;border-radius: 2px;margin:0px;transition: 0.5s all;float:right;cursor: pointer;}
.c2pay_card{min-height: 330px;}
.c2p_logo{height: 15px;margin-left:5px;}
src-otp-input,src-otp-input *,src-card-list,src-card-list *,src-otp-channel-selection,src-otp-channel-selection *{font-family:"Poppins","-apple-system,BlinkMacSystemFont","Segoe UI","Roboto","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important;}
.add_new_wrap{float: left;width: 100%;margin-bottom: 8px;text-align: right;}
.add_new_wrap a{display: inline-block;text-decoration: none;color:#025dfa;cursor: pointer;font-size: 13px;font-weight: 400;}
.clickToPayFrame{float: left;width:100%;-webkit-overflow-scrolling: touch;margin:0px;overflow-y: auto;position: absolute;top: 0;left: 0;bottom: 0;border: 0;height:100%;}
.otp_input {float:left;position: relative;padding-left: 35px;margin:0 20px 0 0;cursor: pointer;font-size:15px;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;font-weight: normal;text-transform: capitalize;}
.otp_input input {position: absolute;opacity: 0;cursor: pointer;}
.otp_check {position: absolute;top: 0;left: 0;height: 25px;width: 25px;background-color: #eee;border-radius: 50%;}
.otp_input:hover input ~ .otp_check {background-color: #ccc;}
.otp_input input:checked ~ .otp_check {background-color: #2196F3;}
.otp_check:after {content: "";position: absolute;display: none;}
.otp_input input:checked ~ .otp_check:after {display: block;}
.otp_input .otp_check:after {top: 9px;left: 9px;width: 8px;height: 8px;border-radius: 50%;background: #fff;}
.click2payOtpListShow{margin:0px;float:left;width:100%;padding: 12px;}
.otp_label{float:left;width:100%;font-size:13px;color:#000;margin:15px 0 20px;}
.paynow_card{font-size: 13px;font-weight: 400;color: #fff;background: var(--main-theme-color);padding:6px 10px;border-radius: 2px;margin:0px;transition: 0.5s all;float:right;cursor: pointer;}
.c2pay_card{min-height: 330px;}
.c2p_logo{height: 15px;margin-left:5px;}
src-otp-input,src-otp-input *,src-card-list,src-card-list *,src-otp-channel-selection,src-otp-channel-selection *{font-family:"Poppins","-apple-system,BlinkMacSystemFont","Segoe UI","Roboto","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important;}
.pay_cvv_input {width: 90px;border: 1px solid #e1e1e1;margin: 0 0 0 10px;padding: 7px 10px;height: 30px;}
.credit-card {margin: 0 0 20px 0;float:left;width:100%;}
.credit-card.selectable:hover {box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);}
.credit-card-last4 {font-size: 14px;float:left;width:100%;margin:0px;line-height:20px;}
.credit-card-name{font-size: 12px;float:left;width:100%;margin:0 0 10px;line-height:20px;color:#777;}
.credit-card-expiry {font-size: 12px;float:right;margin:0px; width: auto;}
.card_no_star{font-size:16px;}
.expiry_label{float:left;width:auto;margin:0 7px 0 0;color:#999;}
.credit-card-cvv{width:90px;float: left;margin:0px;}
.credit-card-cvv-input{border: 0;width: 100%;padding: 4px 7px;background: #fff;border: 1px solid #e1e1e1;}
.card_img{float:right;height:20px;}
.credit_label_wrap{font-weight:400;margin:0;position: relative;cursor: pointer;float:left;width:100%;border-radius: 7px;transition: all 0.4s ease;box-shadow: 0 2px 4px 0 #cfd7df;padding: 10px;color: #333;}
.credit_label_wrap_input{display: none;}
.credit_label_wrap_input:checked + .credit_label_wrap{box-shadow: 0 2px 1px 0 var(--main-theme-color);}
.credit_label_wrap_btm{float:left;width:100%;margin:10px 0 0;}
.credit_label_wrap_input:checked + .credit_label_wrap .credit_label_wrap_btm{display: block !important;}
#src-learn-more{position:absolute;left:0;right:0;top:0;bottom:0;z-index:10;background:#fff;}
.error_card_list_pay{float: left;width: 100%;color: #ff0000;font-size: 12px;margin:10px 0;text-align: center;}
.not_your_cards {color: #777 !important;margin:5px 10px 0 0;float:left;}
#error_log{position: fixed;right:0;top:0;bottom:0;width:500px;word-wrap: break-word;overflow-y: auto;font-size:14px;}
.pay_ontherway{position: absolute;bottom: 145px;right: 15px;}
.pay_resend{color: #000;font-size: 11px;cursor: pointer; text-decoration: underline;}
.pay_resend:hover{color: #ff0000;text-decoration: underline;}
.learnmore_txt {font-size: 12px;margin-left: 5px;}